import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/games.scss"
import "../../styles/game-page.scss"

// Images
import bg from "../../images/data/alchemist-adventure/meta/bg.jpg"

import keyart from "../../images/data/alchemist-adventure/hero/keyart.jpg"
import keyart_thumbnail from "../../images/data/alchemist-adventure/hero/keyart-thumbnail.jpg"
import gameplay_thumbnail from "../../images/data/alchemist-adventure/hero/gameplay-thumbnail.jpg"

import game_about from "../../images/data/alchemist-adventure/meta/icon.jpg"
import game_logo from "../../images/data/alchemist-adventure/meta/logo.png"
import game_developer from "../../images/data/alchemist-adventure/meta/developer.svg"

import slide_1 from "../../images/data/alchemist-adventure/slider/a.jpg"
import slide_2 from "../../images/data/alchemist-adventure/slider/b.jpg"
import slide_3 from "../../images/data/alchemist-adventure/slider/c.jpg"
import slide_4 from "../../images/data/alchemist-adventure/slider/d.jpg"
import slide_5 from "../../images/data/alchemist-adventure/slider/e.jpg"
import slide_6 from "../../images/data/alchemist-adventure/slider/f.jpg"
import slide_7 from "../../images/data/alchemist-adventure/slider/g.jpg"
import slide_8 from "../../images/data/alchemist-adventure/slider/h.jpg"
import slide_9 from "../../images/data/alchemist-adventure/slider/i.jpg"
import slide_10 from "../../images/data/alchemist-adventure/slider/j.jpg"

import award_1 from "../../images/data/alchemist-adventure/awards/1.png"
import award_2 from "../../images/data/alchemist-adventure/awards/2.png"
import award_3 from "../../images/data/alchemist-adventure/awards/3.png"
import award_4 from "../../images/data/alchemist-adventure/awards/4.png"
import award_5 from "../../images/data/alchemist-adventure/awards/5.png"
import award_6 from "../../images/data/alchemist-adventure/awards/6.png"
import award_7 from "../../images/data/alchemist-adventure/awards/7.png"

import press1 from "../../images/data/alchemist-adventure/press/1.png"

import thumbnail_1 from "../../images/data/broken-lines/thumbnail.jpg"
import thumbnail_2 from "../../images/data/metaphora/thumbnail.jpg"
import thumbnail_3 from "../../images/data/raji/thumbnail.jpg"

import game_logo_1 from "../../images/data/broken-lines/meta/logo.png"
import game_logo_2 from "../../images/data/metaphora/meta/logo.png"
import game_logo_3 from "../../images/data/raji/meta/logo.png"

// Videos
import video from "../../videos/alchemist.mp4"

// Gallery

const GamePage = () => {

  return (
    <Layout>

      <Helmet>
        <body className="game-page"/>
      </Helmet>

      <SEO title="Alchemist Adventure"/>
      <Link to="/" className="logo" activeClassName="-active">Super!com</Link>
      <Link to="/games/" className="title">Games</Link>

      <div className="games">

        <div className="center">

          <section className="games__card -alone">

            <div className="games__card__bg" style={{backgroundImage: 'url(' + bg + ')'}}></div>
            
            <h3 className="games__card__title">
              <span className="games__card__title__link">
                Alchemist <br/>
                Adventure
              </span>
            </h3>

            <div className="games__card__content">
              <div className="games__card__video">
                <div className="games__card__video__visible">
                  <img src={keyart} className="games__card__video__visible__size-setter"  alt=""/>
                  <div className="games__card__video__visible__item" data-id="keyart">
                    <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                      <source src={video} type="video/mp4"/>
                    </video>
                    <img src={keyart} className="games__card__video__visible__item__media"  alt=""/>
                  </div>
                  <div className="games__card__video__visible__item" data-id="gameplay">
                    <YouTube
                      videoId="cRhg-hYDD9k"
                      opts={{
                        playerVars: {
                          controls: 0,
                          playlist: 'cRhg-hYDD9k',
                          loop: 1,
                          showinfo: 0,
                          autoplay: 1,
                          start: 0,
                          mute: 1,
                          enablejsapi: 1
                        }
                      }}
                      // onReady={_onReady}
                    ></YouTube>
                  </div>
                </div>
                <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + keyart_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Key Art</span>
                </button>
                <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + gameplay_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Gameplay</span>
                </button>
              </div>
            </div>

            <footer className="games__card__footer">
              <div className="games__card__footer__about">
                <div className="games__card__footer__about__image">
                  <img src={game_about} alt="Alchemist Adventure"/>
                </div>
                <div className="games__card__footer__about__text">
                  An action-adventure game set in fantasy world. Follow the story of Mya, a young alchemist trying to solve the mystery of a forgotten land while searching for her loved ones. Solve puzzles and fight monsters using the power of alchemy. Combine fire, water, air, earth, and a whole host of different ingredients in your concoctions.
                  <div className="games__card__footer__about__text__social">
                    <a href="https://www.youtube.com/channel/UC3S4Ls0esNbILFDu5YGEtvQ" className="games__card__footer__about__text__social__item -youtube" target="_blank" rel="noopener noreferrer">youtube</a>
                    <a href="https://www.facebook.com/alchemistadventure/" className="games__card__footer__about__text__social__item -facebook" target="_blank" rel="noopener noreferrer">facebook</a>
                    <a href="https://twitter.com/alchemist_adv" className="games__card__footer__about__text__social__item -twitter" target="_blank" rel="noopener noreferrer">twitter</a>
                    <a href="https://www.instagram.com/alchemistadventureofficial/" className="games__card__footer__about__text__social__item -instagram" target="_blank" rel="noopener noreferrer">instagram</a>
                    <a href="https://discordapp.com/invite/NPdqd2M" className="games__card__footer__about__text__social__item -discord" target="_blank" rel="noopener noreferrer">discord</a>
                    <a href="https://www.reddit.com/r/AlchemistAdventure/" className="games__card__footer__about__text__social__item -reddit" target="_blank" rel="noopener noreferrer">reddit</a>
                  </div>
                  <div className="games__card__footer__about__text__developer">
                    <div className="games__card__footer__about__text__developer__label">Game developer</div>
                    <a href="https://www.badminions.com.br/" className="games__card__footer__about__text__developer__logo" target="_blank" rel="noopener noreferrer">
                      <img src={game_developer} alt=""/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="games__card__footer__stores">
                <div className="games__card__footer__stores__line">
                  <a href="https://store.steampowered.com/app/521620/Alchemist_Adventure/?utm_source=supercom&utm_medium=gamesk&utm_campaign=storeicon" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                  <a href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Alchemist-Adventure-1971435.html?s=countries" className="games__card__footer__stores__line__item -switch" target="_blank" rel="noopener noreferrer">switch</a>
                  <a href="https://store.playstation.com/en-us/product/UP5729-CUSA20608_00-CHEMISTADVENTURE" className="games__card__footer__stores__line__item -ps" target="_blank" rel="noopener noreferrer">ps</a>
                </div>
                <div className="games__card__footer__stores__line">
                  <a href="https://www.microsoft.com/en-us/p/alchemist-adventure/9pfv931bvlps?activetab=pivot:overviewtab" className="games__card__footer__stores__line__item -xbox" target="_blank" rel="noopener noreferrer">xbox</a>
                  <a href="https://www.gog.com/game/alchemist_adventure" className="games__card__footer__stores__line__item -gog" target="_blank" rel="noopener noreferrer">gog</a>
                </div>
              </div>
              <a href="https://alchemistadventure.com/" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                <img src={game_logo} className="games__card__footer__logo" alt="Alchemist Adventure"/>
              </a>
            </footer>
            
          </section>

        </div>

      </div>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Key <span className="game-section__title__text__accent">Features</span>
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__features">
              <div className="game-section__features__item">
                <b>Discover the <br/>
                mysterious world</b>
              </div>
              <div className="game-section__features__item">
                <b>Combine elements</b> <br/>
                and make all kinds <br/>
                of potions
              </div>
              <div className="game-section__features__item">
                <b>Transform the <br/>
                world</b> around you
              </div>
              <div className="game-section__features__item">
                <b>Solve alchemical <br/>
                puzzles</b>
              </div>
              <div className="game-section__features__item">
                Fight <b>all kinds <br/>
                of monsters</b>
              </div>
              <div className="game-section__features__item">
                <b>Switch between <br/>
                characters</b> or play <br/>
                co-op
              </div>
            </div>

            <div className="game-section__slider -collapsed">
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <li className="glide__slide">
                      <img src={slide_1} alt="" data-index="0"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_2} alt="" data-index="1"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_3} alt="" data-index="2"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_4} alt="" data-index="3"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_5} alt="" data-index="4"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_6} alt="" data-index="5"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_7} alt="" data-index="6"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_8} alt="" data-index="7"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_9} alt="" data-index="8"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_10} alt="" data-index="9"/>
                    </li>
                  </ul>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                  <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Game <span className="game-section__title__text__accent">Trailer</span>
            </span>
          </h4>

          <div className="game-section__content -trailer">
            <YouTube
              videoId="CumQZBB5kks"
              opts={{
                playerVars: {
                  controls: 1,
                  playlist: 'CumQZBB5kks',
                  loop: 1,
                  showinfo: 0,
                  autoplay: 0,
                  start: 0,
                  mute: 1,
                  enablejsapi: 1
                }
              }}
              // onReady={_onReady}
            ></YouTube>
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Awards <span className="game-section__title__text__accent">We Are Proud Of</span>
            </span>
          </h4>

          <div className="game-section__content">
            <div className="game-section__awards">
              <div className="game-section__awards__item">
                <img src={award_1} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_2} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_3} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_4} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_5} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_6} alt=""/>
              </div>
              <div className="game-section__awards__item">
                <img src={award_7} alt=""/>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Press <span className="game-section__title__text__accent">About Us</span>
            </span>
          </h4>

          <div className="game-section__content">
            
            <div className="game-section__quotes-group">
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://www.shacknews.com/article/119899/shackstream-talking-alchemist-adventure-elemental-action-with-bad-minions" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  ShackStream: Talking Alchemist Adventure & elemental action with Bad Minions
                </Link>
              </div>
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://bleedingcool.com/games/alchemist-adventure-shows-off-a-new-in-depth-trailer/" className="game-section__quote__text" target="_blank" rel="noopener noreferrer">
                  Alchemist Adventure Shows Off A New In-Depth Trailer
                </Link>
              </div>
            </div>

            <div className="game-section__image">
              <img src={press1} alt=""/>
            </div>
            
            <div className="game-section__quotes-group">
              <div className="game-section__quote">
                <img src={game_logo} className="game-section__quote__image" alt=""/>
                <Link to="https://www.thegamer.com/control-elements-alchemist-adventure-coming-fall/" className="game-section__quote__text">
                  Control The Elements In Alchemist Adventure Coming This Fall
                </Link>
              </div>
            </div>

            <div className="game-section__press-kit">
              <div className="game-section__press-kit__bg" style={{backgroundImage: 'url(' + bg + ')'}}></div>
              <a href="https://app.box.com/s/zyaifcvslf4qcqgcw0tsxml9meqi6r1w" className="game-section__press-kit__link" target="_blank" rel="noopener noreferrer">
                <span className="game-section__press-kit__link__accent-text">Press <br/>Kit</span>
                <span className="game-section__press-kit__link__text">Let's see</span>
              </a>
            </div>

            <button className="content__creator">
              <span className="content__creator__text">If you are content creator</span>
            </button>
            
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title -small">
            <i></i>
            <span className="game-section__title__text">
              You may like our other games
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__recents">
              <Link to="/games/broken-lines/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_1 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>Broken Lines</span>
                </span>
                <span className="game-section__recents__item__text">
                  Broken Lines is a story-driven tactical turnbased
                  RPG set in an alternative WW2
                </span>
                <img src={game_logo_1} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/metaphora/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_2 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>METAPHORA THE MOONY-CAT QUEST</span>
                </span>
                <span className="game-section__recents__item__text">
                  An animated action-adventure with catching
                  story about regenerating the world
                </span>
                <img src={game_logo_2} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/raji/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_3 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>RAJI: AN ANCIENT EPIC</span>
                </span>
                <span className="game-section__recents__item__text">
                  Raji: An Anсient Epic is an action-adventure
                  game set in ancient India.
                </span>
                <img src={game_logo_3} className="game-section__recents__item__image" alt=""/>
              </Link>
            </div>

            <div className="games__promo__button">
              <button className="games__promo__button__link" data-role="call-to-action">
                <span className="games__promo__button__link__text">Give a boost to your game</span>
              </button>
              <div className="games__promo__button__shadow"></div>
            </div>

          </div>

        </div>
      </section>

      <div className="game-section__slider -expanded">
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <li className="glide__slide">
                <img src={slide_1} alt="" data-index="0"/>
              </li>
              <li className="glide__slide">
                <img src={slide_2} alt="" data-index="1"/>
              </li>
              <li className="glide__slide">
                <img src={slide_3} alt="" data-index="2"/>
              </li>
              <li className="glide__slide">
                <img src={slide_4} alt="" data-index="3"/>
              </li>
              <li className="glide__slide">
                <img src={slide_5} alt="" data-index="4"/>
              </li>
              <li className="glide__slide">
                <img src={slide_6} alt="" data-index="5"/>
              </li>
              <li className="glide__slide">
                <img src={slide_7} alt="" data-index="6"/>
              </li>
              <li className="glide__slide">
                <img src={slide_8} alt="" data-index="7"/>
              </li>
              <li className="glide__slide">
                <img src={slide_9} alt="" data-index="8"/>
              </li>
              <li className="glide__slide">
                <img src={slide_10} alt="" data-index="9"/>
              </li>
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
          </div>
        </div>
      </div>
      
    </Layout>
  );

}

export default GamePage
